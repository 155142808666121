import { FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { Input, DatePicker, Button } from '../../../components';

import moment from 'moment';
import { EORIGIN_DATE_FORMAT } from '@e-origin/shared';

export const ImporterGeneralInfo = ({ formik }) => {
  return (
    <div style={{ paddingRight: '50px', marginBottom: '40px' }}>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>ID Card</FormSectionTitle>
        <FormRow>
          <Input
            name="idCard.ownerName"
            placeholder="Owner name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.idCard.ownerName}
            width={50}
            widthUnit="%"
          />

          <DatePicker
            placeholder="Expiration Date"
            width={50}
            widthUnit="%"
            value={
              formik.values.idCard.expirationDate
                ? moment(formik.values.idCard.expirationDate as Date, EORIGIN_DATE_FORMAT).toDate()
                : null
            }
            onChange={(value: Date) =>
              formik.setFieldValue('idCard.expirationDate', value ? moment(value).format(EORIGIN_DATE_FORMAT) : null)
            }
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Chinese business license</FormSectionTitle>
        <FormRow>
          <Input
            name="businessLicense.nameEN"
            placeholder="Company name EN"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessLicense.nameEN}
            width={50}
            widthUnit="%"
          />

          <Input
            name="businessLicense.nameCN"
            placeholder="Company name CN"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessLicense.nameCN}
            width={50}
            widthUnit="%"
          />
        </FormRow>
        <FormRow>
          <Input
            name="idCard.ownerName"
            placeholder="Owner name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.idCard.ownerName}
            width={50}
            widthUnit="%"
            disabled
          />
          <Input
            name="businessLicense.registrationNumber"
            placeholder="Registration number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessLicense.registrationNumber}
            width={50}
            widthUnit="%"
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>EORI importer</FormSectionTitle>
        <FormRow>
          <Input
            name="businessLicense.nameEN"
            placeholder="Company name EN"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessLicense.nameEN}
            width={50}
            widthUnit="%"
            disabled
          />

          <Input
            name="eori.number"
            placeholder="EORI number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.eori.number}
            width={50}
            widthUnit="%"
          />
        </FormRow>
        <FormRow>
          <Input
            name="eori.address.street"
            placeholder="Street & number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.eori.address.street}
            width={50}
            widthUnit="%"
          />
          <Input
            name="eori.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.eori.address.city}
            width={50}
            widthUnit="%"
          />
          <Input
            name="eori.address.zipCode"
            placeholder="Zip code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.eori.address.zipCode}
            width={50}
            widthUnit="%"
          />
          <Input
            name="eori.address.country"
            placeholder="Country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.eori.address.country}
            width={50}
            widthUnit="%"
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>VAT importer</FormSectionTitle>
        <FormRow>
          <Input
            name="businessLicense.nameEN"
            placeholder="Company name EN"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessLicense.nameEN}
            width={50}
            widthUnit="%"
            disabled
          />

          <Input
            name="vat.number"
            placeholder="VAT importer"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vat.number}
            width={50}
            widthUnit="%"
          />
        </FormRow>
        <FormRow>
          <Input
            name="vat.address.street"
            placeholder="Street & number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vat.address.street}
            width={50}
            widthUnit="%"
          />
          <Input
            name="vat.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vat.address.city}
            width={50}
            widthUnit="%"
          />
          <Input
            name="vat.address.zipCode"
            placeholder="Zip code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vat.address.zipCode}
            width={50}
            widthUnit="%"
          />
          <Input
            name="vat.address.country"
            placeholder="Country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vat.address.country}
            width={50}
            widthUnit="%"
          />
        </FormRow>
        <div>
          <Button type="submit" primary>
            Save
          </Button>
        </div>
      </FormSection>
    </div>
  );
};
