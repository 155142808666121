/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-param-last */
/* eslint-disable max-lines */
/* eslint-disable default-case */
/* eslint-disable complexity */
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { FC, useEffect, useRef, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

/* eslint-disable max-statements */
import {
  Batch,
  BatchStatusEnum,
  BatchTypeEnum,
  DeclarationCustomsFlowType,
  DeclarationStatus,
  EnvironmentCountries,
  FilterRisksValues,
  getTotal,
  TemplateType,
} from '@e-origin/shared';

import { ReactComponent as ColumnsViewIcon } from '../../assets/icons/columns-view-icon.svg';
import { ReactComponent as RowsViewIcon } from '../../assets/icons/rows-view-icon.svg';
import {
  BatchViewComponent,
  Button,
  CustomTable,
  IListFilterChangeEvent,
  IListFilterDropdownElements,
  ListFilterDropdown,
  NotificationModal,
  SearchBox,
  SendBatchModal,
  Tab,
  TableActionButtons,
  TableActions,
  Tabs,
} from '../../components';
import {
  BatchesActions,
  BatchesTabs,
  ColumnVisibilityLabels,
  ColumnVisibilityValues,
  FormatValueTypes,
  RenderAnalysisParams,
} from '../../enums';
import { useConfig } from '../../hooks/use-config';
import {
  archiveBatch,
  cleanupArchivedBatch,
  clearBatches,
  deleteBatch,
  downloadOriginalFileFromCloud,
  exportScreenshots,
  fetchBatches,
  fetchTemplates,
  fetchUsers,
  generateBatchReport,
  generateBatchRiskAnalysisReport,
  generateCustomerReport,
  generateInvoice,
  generateNlDecoTestFile,
  nextBatches,
  runRiskAnalysisCalculations,
  selectBatches,
  selectBatchesFilters,
  selectBatchesPageLoading,
  selectBatchesPagination,
  selectBatchesSorting,
  selectTemplates,
  selectTotalBatchesCount,
  selectUsers,
  sendBatchRiskAnalysisReport,
  setBatchesFilters,
  setBatchesSorting,
  syncBatchWithCustoms,
} from '../../stores/batchesSlice';
import { fetchCustomers, selectCustomers, setCustomersLoading } from '../../stores/customersSlice';
import { selectDeclarant } from '../../stores/declarantSlice';
import { setDetails } from '../../stores/declarationsSlice';
import { selectSettings } from '../../stores/settingsSlice';
import { selectUser } from '../../stores/userSlice';
import { DataTableControls, PageContainer, PageHeader, PageTitle } from '../../styles/common';
import { formatValue, STORAGE_KEYS } from '../../utils';
import RiskAnalysisModal from '../declaration-details/tabs/risk-analysis-modal/risk-analysis-modal.component';
import BatchProgressIndicator from './batch-progress-indicator/batch-progress-indicator.component';
import BatchStatusIndicator from './batch-status-indicator/batch-status-indicator.component';
import BatchesBoard from './batches-board/batches-board.component';
import * as Style from './batches.style';
import BulkUpdateBatchModal from './bulk-update-batch-modal/bulk-update-batch-modal.component';
import FailedDetailsModal from './failed-details-modal/failed-details-modal.component';
import { FinalizeBatchModal } from './finalize-batch-modal/finalize-batch-modal';
import InvalidateBatchModal from './invalidate-batch-modal/invalidate-batch-modal.component';
import NewBatchModal from './new-batch-modal/new-batch-modal.component';
import { StatisticLink } from './new-batch-modal/new-batch-modal.style';
import UpdateBatchModal, { INVALIDATE_BATCH } from './update-batch-modal/update-batch-modal.component';

type BatchesParamsType = {
  customerId: string;
};

const ADDITIONAL_COLUMNS_STYLES = {
  backgroundColor: '#F4F9FF',
};

const Batches: FC = () => {
  const filters = useSelector(selectBatchesFilters);
  const sorting = useSelector(selectBatchesSorting);
  const pagination = useSelector(selectBatchesPagination);

  const isPageLoading = useSelector(selectBatchesPageLoading);

  const criteriaInitialized = useRef<boolean>(false);
  const firstQuery = useRef<boolean>(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const { config } = useConfig();

  const batchViewCookies = Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) || '';

  const { customerId } = useParams<BatchesParamsType>();

  const [showNewBatchModal, setShowNewBatchModal] = useState(false);
  const [showUpdateBatchModal, setShowUpdateBatchModal] = useState(false);
  const [showGenerateBatchInvoiceModal, setShowGenerateBatchInvoiceModal] = useState(false);
  const [showLoadANewFileBatchModal, setShowLoadANewFileBatchModal] = useState(false);
  const [showInvalidateBatchModal, setShowInvalidateBatchModal] = useState(false);
  const [showBulkUpdateBatchModal, setShowBulkUpdateBatchModal] = useState(false);
  const [showBatchNotificationModal, setShowBatchNotificationModal] = useState(false);
  const [showFailedBatchDeleteNotificationModal, setShowFailedBatchDeleteNotificationModal] = useState(false);
  const [showFailedDetailsModal, setShowFailedDetailsModal] = useState(false);
  const [showFinalizeBatchModal, setShowFinalizeBatchModal] = useState(false);
  const [showArchiveBatchNotificationModal, setShowArchiveBatchNotificationModal] = useState(false);
  const [selectedFailedDetailsRow, setSelectedFailedDetailsRow] = useState<Batch | undefined>(undefined);
  const selectedUpdateRow = useRef<Batch | undefined>(undefined);
  const [selectedFailedRowForDelete, setSelectedFailedRowForDelete] = useState<Batch | undefined>(undefined);
  const [selectedRowForArchive, setSelectedRowForArchive] = useState<Batch | undefined>(undefined);
  const selectedRow = useRef<Batch>(null);
  const [isUnarchive, setIsUnarchive] = useState(false);
  const [viewBatchesAsList, setViewBatchesAsList] = useState(true);
  const [batchesData, setBatchesData] = useState<Batch[]>([]);
  const [batchView, setBatchView] = useState(batchViewCookies as BatchTypeEnum);
  const selectedTabRef = useRef('');

  const initialTotalBatches = useSelector(selectTotalBatchesCount);
  const batchesList = useSelector(selectBatches);
  const usersList = useSelector(selectUsers);
  const templatesList = useSelector(selectTemplates);
  const declarant = useSelector(selectDeclarant);
  const user = useSelector(selectUser);

  const customers = useSelector(selectCustomers);

  const settings = useSelector(selectSettings);

  const currentTab = useRef<BatchesTabs>(BatchesTabs.IN_PROGRESS);
  const tabs = useRef<{ id: BatchesTabs; label: string; value: BatchStatusEnum }[]>([
    { id: BatchesTabs.IN_PROGRESS, label: 'Current', value: undefined },
    { id: BatchesTabs.ARCHIVED, label: 'Archived', value: BatchStatusEnum.ARCHIVED },
  ]);

  const storeCriteria = () => {
    Cookies.set(STORAGE_KEYS.COOKIES.LIST_BATCHES_CRITERIA, JSON.stringify({ filters, sorting, pagination }));
  };

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }
    storeCriteria();
    if (viewBatchesAsList) {
      dispatch(fetchBatches({ persistPagination: firstQuery.current }));
      firstQuery.current = false;
    }
  }, [filters, sorting, pagination.size]);

  useEffect(() => {
    storeCriteria();
  }, [pagination]);

  const toggleViewMode = async () => {
    dispatch(clearBatches());

    if (!viewBatchesAsList) {
      dispatch(fetchBatches());
    }

    setViewBatchesAsList(!viewBatchesAsList);
  };

  useEffect(() => {
    if (!templatesList.length) {
      loadData();
    }

    return () => {
      dispatch(clearBatches());
    };
  }, []);

  useEffect(() => {
    if (user?._id && !filters.filterByUsers?.length) {
      dispatch(
        setBatchesFilters({
          filterByUsers: [user._id],
        }),
      );
    }

    if (user?._id && templatesList.length) {
      criteriaInitialized.current = true;

      dispatch(
        setBatchesFilters({
          status: currentTab.current === BatchesTabs.ARCHIVED ? BatchStatusEnum.ARCHIVED : undefined,
          filterByUsers: filters.filterByUsers || [user._id],
          templateType: [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)
            ? TemplateType.HIGH_VALUE_H1
            : TemplateType.LOW_VALUE_H7,
        }),
      );
    }
  }, [user, templatesList]);

  useEffect(() => {
    if (customerId) {
      setBatchesData(
        batchesList.filter((batch) => {
          return batch.customer === customerId;
        }),
      );
    } else {
      setBatchesData(batchesList);
    }
  }, [batchesList]);

  useEffect(() => {
    if (declarant?._id) {
      dispatch(fetchUsers(declarant._id));
      dispatch(setCustomersLoading());
      dispatch(fetchCustomers());
    }
  }, [declarant]);

  const buildDefaultColumnVisibility = () => {
    const defaultColumnsVisiblity = {
      [ColumnVisibilityValues.RISK_ANALYSIS]: {
        label: ColumnVisibilityLabels.RISK_ANALYSIS,
        checked: true,
      },
      [ColumnVisibilityValues.SENDING]: {
        label: ColumnVisibilityLabels.SENDING,
        checked: true,
      },
      [ColumnVisibilityValues.FILE_NAME]: {
        label: ColumnVisibilityLabels.FILE_NAME,
        checked: true,
      },
      [ColumnVisibilityValues.BATCH_NAME]: {
        label: ColumnVisibilityLabels.BATCH_NAME,
        checked: true,
      },
      [ColumnVisibilityValues.CUSTOMER]: {
        label: ColumnVisibilityLabels.CUSTOMER,
        checked: true,
      },
      [ColumnVisibilityValues.DATE]: {
        label: ColumnVisibilityLabels.DATE,
        checked: true,
      },
      [ColumnVisibilityValues.TEMPLATE_NAME]: {
        label: ColumnVisibilityLabels.TEMPLATE_NAME,
        checked: true,
      },
    };

    if (localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.LIST_BATCHES_COLUMNS) !== null) {
      const storedColumnsVisibility: string[] = (
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE.LIST_BATCHES_COLUMNS) || ''
      ).split(',');

      Object.keys(defaultColumnsVisiblity).forEach((key) => {
        defaultColumnsVisiblity[key].checked = storedColumnsVisibility.includes(key);
      });
    }

    return defaultColumnsVisiblity;
  };
  const [columnsDropdownOptions, setColumnsDropdownOptions] = useState<IListFilterDropdownElements>(
    buildDefaultColumnVisibility(),
  );

  const updateColumnsVisibility = (columnOption: IListFilterChangeEvent) => {
    const columnsDropdownOptionsCopy = { ...columnsDropdownOptions };
    if (!columnOption.id) {
      Object.keys(columnsDropdownOptionsCopy).forEach((key) => {
        columnsDropdownOptionsCopy[key].checked = columnOption.checked;
      });
    } else {
      columnsDropdownOptionsCopy[columnOption.id].checked = columnOption.checked;
    }

    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE.LIST_BATCHES_COLUMNS,
      Object.keys(columnsDropdownOptionsCopy)
        .filter((key) => columnsDropdownOptionsCopy[key].checked)
        .join(','),
    );

    setColumnsDropdownOptions(columnsDropdownOptionsCopy);
  };

  const usersDropdownOptions = useRef<IListFilterDropdownElements>({});
  useEffect(() => {
    usersDropdownOptions.current = buildUsersDropdownOptions();
  }, [usersList, filters]);

  const buildUsersDropdownOptions = () => {
    const usersDropdownOptionList: IListFilterDropdownElements = {};

    usersList.forEach((usr) => {
      usersDropdownOptionList[usr._id] = {
        label: `${usr.firstName} ${usr.lastName}`,
        checked: !!filters.filterByUsers?.find((userIdFromCriteria) => userIdFromCriteria === usr._id),
      };
    });

    return usersDropdownOptionList;
  };

  const [openRiskAnalsisModal, setOpenRiskAnalsisModal] = useState(false);

  const handleTableAction = (actionType: string, row: Batch) => {
    switch (actionType) {
      case BatchesActions.VIEW: {
        if (row.status.value === 'failed') {
          showFailedModalDetails(row);
        } else {
          history.push({
            pathname: `/declarations`,
            search: `?batchId=${row._id}&tab=${DeclarationStatus.NOT_SENT}`,
          });
        }
        return;
      }
      case BatchesActions.GENERATE_CUSTOMS_REPORT: {
        dispatch(generateBatchReport(row._id));
        return;
      }
      case BatchesActions.GENERATE_RISK_ANALYSIS_REPORT: {
        dispatch(generateBatchRiskAnalysisReport(row._id, row.filename));
        return;
      }
      case BatchesActions.GENERATE_CUSTOMER_REPORT: {
        dispatch(generateCustomerReport(row._id));
        return;
      }
      case BatchesActions.GENERATE_INVOICE: {
        handleSetSelectedUpdateRow(row);
        if (row.cloudBucketInvoiceUrl) {
          dispatch(generateInvoice(row._id, row.filename));
        } else {
          toggleGenerateBatchInvoiceModal();
        }
        return;
      }
      case BatchesActions.EXPORT_SCREENSHOTS: {
        handleSetSelectedUpdateRow(row);
        dispatch(exportScreenshots(row._id));
        return;
      }
      case BatchesActions.EXECUTE_RISK_ANALYSIS: {
        handleSetSelectedUpdateRow(row);
        setOpenRiskAnalsisModal(true);
        return;
      }
      case BatchesActions.SEND_RISK_ANALYSIS_REPORT: {
        dispatch(sendBatchRiskAnalysisReport(row._id, true));
        return;
      }
      case BatchesActions.Finalize: {
        if (row) {
          selectedRow.current = row;
        }
        toggleFinalizeBatchModal();
        return;
      }
      case BatchesActions.DELETE: {
        showDeleteFailedModal(row);
        return;
      }
      case BatchesActions.ARCHIVE: {
        showArchiveModal(row);
        return;
      }
      case BatchesActions.UPDATE: {
        handleSetSelectedUpdateRow(row);
        toggleUpdateBatchModal();
        return;
      }
      case BatchesActions.DOWNLOAD: {
        if (row.hasFileInCloud) {
          dispatch(downloadOriginalFileFromCloud(row._id, row.filename));
        }
        return;
      }
      case BatchesActions.CUSTOMS_SYNC: {
        dispatch(syncBatchWithCustoms(row));
        return;
      }
      case BatchesActions.RISK_ANALYSIS_CALCULATIONS: {
        dispatch(runRiskAnalysisCalculations(row._id));
        return;
      }
      /* NL DECO testing - to be removed - START */
      case /generateNlDecoFiles/.test(actionType) ? actionType : '': {
        const [, messageType] = actionType.match(/generateNlDecoFiles-(.*)/);
        dispatch(generateNlDecoTestFile(row._id, messageType));
        return;
      }
      /* NL DECO testing - to be removed - END */
      default: {
        selectedRow.current = row;
        toggleBatchNotificationModal();
      }
    }
  };

  const handleArchivedTableAction = (actionType: string, row: Batch) => {
    switch (actionType) {
      case BatchesActions.VIEW: {
        history.push(`/declarations?batchId=${row._id}&archived=true`);
        return;
      }
      case BatchesActions.GENERATE_CUSTOMS_REPORT: {
        dispatch(generateBatchReport(row._id));
        return;
      }
      case BatchesActions.GENERATE_RISK_ANALYSIS_REPORT: {
        dispatch(generateBatchRiskAnalysisReport(row._id, row.filename));
        return;
      }
      case BatchesActions.ARCHIVE: {
        showArchiveModal(row, true);
        return;
      }
      case BatchesActions.UPDATE: {
        handleSetSelectedUpdateRow(row);
        toggleUpdateBatchModal();
        return;
      }
      case BatchesActions.DOWNLOAD: {
        if (row.hasFileInCloud) {
          dispatch(downloadOriginalFileFromCloud(row._id, row.filename));
        }
        return;
      }
      case BatchesActions.GENERATE_INVOICE: {
        handleSetSelectedUpdateRow(row);
        if (row.cloudBucketInvoiceUrl) {
          dispatch(generateInvoice(row._id, row.filename));
        } else {
          toggleGenerateBatchInvoiceModal();
        }
        return;
      }
      case BatchesActions.CLEANUP: {
        executeArchiveCleanup(row._id);
        return;
      }
      default: {
        showDeleteFailedModal(row);
      }
    }
  };

  const handleStatisticsClick = (row: Batch, tab: string, filter?: FilterRisksValues) => {
    if (row.status.value !== 'failed') {
      let path = `/declarations?batchId=${row._id}&tab=${tab}`;
      if (filter) {
        path = `${path}&riskValues=${filter}`;
      }
      history.push(path);
    }
  };

  const executeArchiveCleanup = async (batchId: string) => {
    dispatch(cleanupArchivedBatch(batchId));
    await loadBatches();
  };

  const loadData = async () => {
    dispatch(fetchTemplates());
  };

  const loadBatches = async (batchViewType?: BatchTypeEnum) => {
    if (templatesList && templatesList.length) {
      const tableView = batchViewType ?? batchView;
      let batchType: TemplateType;
      if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(tableView)) {
        batchType = TemplateType.HIGH_VALUE_H1;
      }
      if (tableView === BatchTypeEnum.LOW_VALUES) {
        batchType = TemplateType.LOW_VALUE_H7;
      }

      let status: BatchStatusEnum;
      if (currentTab.current) {
        if (currentTab.current === BatchesTabs.ARCHIVED) {
          status = BatchStatusEnum.ARCHIVED;
        }
      } else if (selectedTabRef.current === BatchesTabs.ARCHIVED) {
        status = BatchStatusEnum.ARCHIVED;
      }

      dispatch(
        setBatchesFilters({
          isExport: tableView === BatchTypeEnum.HIGH_VALUES_EXPORT,
          templateType: batchType,
          status,
        }),
      );
    }
  };

  const searchBatches = (searchQuery: string) => {
    dispatch(setBatchesFilters({ searchQuery }));
  };

  const toggleNewBatchModal = () => {
    setShowNewBatchModal(!showNewBatchModal);
  };

  const toggleUpdateBatchModal = () => {
    setShowUpdateBatchModal(!showUpdateBatchModal);
  };

  const toggleGenerateBatchInvoiceModal = async () => {
    setShowGenerateBatchInvoiceModal(!showGenerateBatchInvoiceModal);
  };

  const handleSetSelectedUpdateRow = (row: Batch) => {
    if (row) {
      selectedUpdateRow.current = row;
    }
  };

  const toggleLoadANewFileBatchModal = () => {
    setShowLoadANewFileBatchModal(!showLoadANewFileBatchModal);
  };

  const toggleInvalidateBatchModal = () => {
    setShowInvalidateBatchModal(!showInvalidateBatchModal);
  };

  const toggleBulkUpdateBatchModal = () => {
    setShowBulkUpdateBatchModal(!showBulkUpdateBatchModal);
  };

  const toggleBatchNotificationModal = () => {
    setShowBatchNotificationModal(!showBatchNotificationModal);
  };

  const toggleDeleteFailedBatchNotificationModal = () => {
    setShowFailedBatchDeleteNotificationModal(!showFailedBatchDeleteNotificationModal);
  };

  const toggleArchiveBatchNotificationModal = (isUnarchiveFlag = false) => {
    setIsUnarchive(isUnarchiveFlag);
    setShowArchiveBatchNotificationModal(!showArchiveBatchNotificationModal);
  };

  const toggleFailedDetailsModal = () => {
    if (showFailedDetailsModal) {
      setSelectedFailedDetailsRow(undefined);
    }
    setShowFailedDetailsModal(!showFailedDetailsModal);
  };

  const showFailedModalDetails = (row: Batch) => {
    setSelectedFailedDetailsRow(row);
    toggleFailedDetailsModal();
  };

  const showDeleteFailedModal = (row: Batch) => {
    setSelectedFailedRowForDelete(row);
    toggleDeleteFailedBatchNotificationModal();
  };

  const showArchiveModal = (row: Batch, isUnarchiveFlag = false) => {
    setSelectedRowForArchive(row);
    toggleArchiveBatchNotificationModal(isUnarchiveFlag);
  };

  const toggleFinalizeBatchModal = () => {
    setShowFinalizeBatchModal(!showFinalizeBatchModal);
  };

  const handleFinalizeBatchHide = () => {
    toggleFinalizeBatchModal();
    dispatch(fetchBatches());
  };

  const handleBatchDelete = () => {
    if (selectedFailedRowForDelete) {
      if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)) {
        dispatch(deleteBatch(selectedFailedRowForDelete._id, true));
      } else {
        dispatch(deleteBatch(selectedFailedRowForDelete._id));
      }
    }
  };

  const handleBatchArchive = () => {
    if (selectedRowForArchive) {
      dispatch(archiveBatch(selectedRowForArchive._id, isUnarchive));
    }
  };

  const decideIfSendAllButtonIsAvailable = (row: Batch): boolean => {
    if (!row.statistics) {
      return false;
    }
    if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)) {
      if (
        row.statistics.beGateSent === row.statistics.total &&
        row.statistics.beGateSent > row.statistics.beGateNotReleased + row.statistics.beGateReleased &&
        row.statistics.rejected === 0
      ) {
        return false;
      }

      const notInProgress = row.status.value !== BatchStatusEnum.IN_PROGRESS;
      const sentWithNoRejection =
        row.status.value !== BatchStatusEnum.SUCCESS ||
        row.statistics?.sent < row.statistics?.total ||
        (row.statistics?.sent === row.statistics?.total && row.statistics?.rejected > 0);

      if (
        notInProgress &&
        sentWithNoRejection &&
        declarant.hasExternalSystemCustoms &&
        row.status.subStatus !== BatchStatusEnum.ANALYSIS
      ) {
        return true;
      }
      return false;
    }

    if (row.status.value === BatchStatusEnum.SENDING) {
      return false;
    }

    const showPre = (b: Batch) =>
      getTotal(b, DeclarationStatus.NOT_SENT, DeclarationCustomsFlowType.PRE) > 0 ||
      getTotal(b, DeclarationStatus.ANALYSIS, DeclarationCustomsFlowType.PRE) > 0 ||
      getTotal(b, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.PRE) > 0;

    const showPost = (b: Batch) =>
      getTotal(b, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.PRE) > 0 ||
      getTotal(b, DeclarationStatus.IN_CONTROL, DeclarationCustomsFlowType.PRE) > 0 ||
      getTotal(b, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.POST) > 0 ||
      getTotal(b, DeclarationStatus.IN_CONTROL, DeclarationCustomsFlowType.POST) > 0;

    if (config?.COUNTRY === EnvironmentCountries.NL) {
      // account for PRE/POST flows
      return showPre(row) || showPost(row);
    }

    return (
      row.statistics?.sent < row.statistics?.total ||
      (row.statistics?.sent === row.statistics?.total && row.statistics?.rejected > 0)
    );
  };

  const deleteAllowed = (row: Batch) => {
    if (![BatchStatusEnum.SUCCESS, BatchStatusEnum.ARCHIVED].includes(row.status.value as BatchStatusEnum)) {
      return true;
    }

    if (
      (row.statistics?.beGateSent > 0 || row.statistics?.sent > 0) &&
      row.templateType === TemplateType.HIGH_VALUE_H1
    ) {
      return false;
    }

    return (
      !row.statistics?.sent &&
      !row.statistics?.accepted &&
      !row.statistics?.released &&
      !row.statistics?.notReleased &&
      !row.statistics?.invalidated
    );
  };

  const sendingColumns: IDataTableColumn<Batch>[] = [
    ...(config?.COUNTRY === EnvironmentCountries.BE &&
    [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)
      ? [
          {
            name: 'BE-gate released',
            selector: 'statistics.beGateReleased',
            sortable: true,
            width: '80px',
            cell: (row) => {
              const beGateReleasedPercentage =
                (100 * (row.statistics?.beGateReleased || 0)) / (row.statistics?.total || 1);

              return (
                <StatisticLink
                  disabled={row.status.value === 'failed'}
                  isCompleted={beGateReleasedPercentage === 100}
                  onClick={() => {
                    if (row.status.value !== 'failed') {
                      history.push(`/declaration-groups?batchId=${row._id}`);
                    }
                  }}
                >
                  <span style={row.statistics?.beGateNotReleased > 0 ? { color: '#ff3d3d' } : {}}>
                    {row.statistics ? `${row.statistics?.beGateReleased || 0}/${row.statistics?.total}` : 0}
                  </span>
                </StatisticLink>
              );
            },
          },
        ]
      : []),
    ...((config?.COUNTRY === EnvironmentCountries.BE && !config?.IS_PROD && batchView === BatchTypeEnum.HIGH_VALUES) ||
    config?.COUNTRY === EnvironmentCountries.NL
      ? [
          {
            name: 'Pre-Arrival Accepted',
            selector: 'statistics.preAccepted',
            sortable: true,
            width: '80px',
            cell: (row) => (
              <StatisticLink
                isCompleted={row.statistics?.preAccepted > 0 && row.statistics?.preAccepted === row.statistics?.total}
                onClick={() => {
                  handleStatisticsClick(row, DeclarationStatus.NOT_SENT);
                }}
              >
                <span style={row.statistics?.inControl > 0 ? { color: '#ff3d3d' } : {}}>
                  {row.statistics ? `${row.statistics?.preAccepted || 0}/${row.statistics?.total}` : 0}
                </span>
              </StatisticLink>
            ),
          },
        ]
      : []),
    {
      name: [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)
        ? 'Sent PLDA'
        : 'Sent post-Arrival',
      selector: 'statistics.sent',
      sortable: true,
      width: '80px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          isCompleted={
            row.statistics && row.statistics?.released > 0 && row.statistics?.released === row.statistics?.total
          }
          onClick={() => {
            if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)) {
              if (row.status.value !== 'failed') {
                history.push(`/declaration-groups?batchId=${row._id}`);
              }
            } else {
              handleStatisticsClick(row, DeclarationStatus.NOT_SENT);
            }
          }}
        >
          <span style={row.statistics?.inControl > 0 ? { color: '#ff3d3d' } : {}}>
            {row.statistics
              ? `${
                  ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)
                    ? row.statistics.sent
                    : row.statistics?.sent) || 0 // temp fix: postSent is not correctly calculated
                }/${row.statistics?.total}`
              : 0}
          </span>
        </StatisticLink>
      ),
    },
    {
      name: 'In Progress %',
      selector: 'statistics.accepted',
      sortable: true,
      width: '100px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          onClick={() => {
            handleStatisticsClick(row, DeclarationStatus.ACCEPTED);
          }}
        >
          {row.statistics ? numeral((row.statistics.accepted / row.statistics.total) * 100).format('0.[00]') : 0}
        </StatisticLink>
      ),
    },
    {
      name: 'Rejected %',
      selector: 'statistics.rejected',
      sortable: true,
      width: '90px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          onClick={() => {
            handleStatisticsClick(row, DeclarationStatus.REJECTED);
          }}
        >
          {row.statistics ? numeral((row.statistics.rejected / row.statistics.total) * 100).format('0.[00]') : 0}
        </StatisticLink>
      ),
    },
    {
      name: 'In control %',
      selector: 'statistics.inControl',
      sortable: true,
      width: '90px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          onClick={() => {
            handleStatisticsClick(row, DeclarationStatus.IN_CONTROL);
          }}
        >
          {row.statistics ? numeral((row.statistics.inControl / row.statistics.total) * 100).format('0.[00]') : 0}
        </StatisticLink>
      ),
    },
    {
      name: 'Not released %',
      selector: 'statistics.notReleased',
      sortable: true,
      width: '100px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          onClick={() => {
            handleStatisticsClick(row, DeclarationStatus.NOT_RELEASED);
          }}
        >
          {row.statistics ? numeral((row.statistics.notReleased / row.statistics.total) * 100).format('0.[00]') : 0}
        </StatisticLink>
      ),
    },
    {
      name: 'Released %',
      selector: 'statistics.released',
      sortable: true,
      width: '85px',
      cell: (row) => (
        <StatisticLink
          disabled={row.status.value === 'failed'}
          onClick={() => {
            handleStatisticsClick(row, DeclarationStatus.RELEASED);
          }}
        >
          {row.statistics ? numeral((row.statistics.released / row.statistics.total) * 100).format('0.[00]') : 0}
        </StatisticLink>
      ),
    },
  ];

  const renderAnalysisWrongParamsFixed = (batch: Batch, type: RenderAnalysisParams) => {
    if (batch.statistics) {
      const analysisWrongDescriptionFixed = batch.statistics?.analysisWrongDescriptionFixed || 0;
      const analysisWrongDescription = batch.statistics?.analysisWrongDescription || 0;

      const analysisWrongHSCodeFixed = batch.statistics?.analysisWrongHsCodeFixed || 0;
      const analysisWrongHsCode = batch.statistics?.analysisWrongHsCode || 0;

      switch (type) {
        case RenderAnalysisParams.DESCRIPTION: {
          if (analysisWrongDescriptionFixed > analysisWrongDescription) {
            return `${analysisWrongDescription}/${analysisWrongDescription}`;
          }
          return `${analysisWrongDescriptionFixed}/${analysisWrongDescription}`;
        }
        case RenderAnalysisParams.HS_CODE: {
          if (analysisWrongHSCodeFixed > analysisWrongHsCode) {
            return `${analysisWrongHsCode}/${analysisWrongHsCode}`;
          }
          return `${analysisWrongHSCodeFixed}/${analysisWrongHsCode}`;
        }
        case RenderAnalysisParams.VALUE: {
          const analysisWrongValueFixed = batch.statistics?.analysisWrongValueFixed || 0;
          const analysisWrongValue = batch.statistics?.analysisWrongValue || 0;

          return analysisWrongValueFixed > analysisWrongValue
            ? `${analysisWrongValue}/${analysisWrongValue}`
            : `${analysisWrongValueFixed}/${analysisWrongValue}`;
        }
      }
    }

    return '';
  };

  const informationsColumns: { [info: string]: IDataTableColumn<Batch>[] } = {
    batchName: [
      {
        name: 'Batch name',
        selector: 'name',
        sortable: true,
        width: '260px',
        style: ADDITIONAL_COLUMNS_STYLES,
      },
    ],
    customer: [
      {
        name: 'Customer',
        selector: (row) => customers.find((c) => c._id === row.customer)?.name || '',
        width: '260px',
        style: ADDITIONAL_COLUMNS_STYLES,
      },
    ],
    fileName: [
      {
        name: 'File name',
        selector: 'filename',
        sortable: true,
        width: '360px',
        style: ADDITIONAL_COLUMNS_STYLES,
      },
    ],
    templateName: [
      {
        name: 'Template name',
        selector: 'template',
        width: '180px',
        style: ADDITIONAL_COLUMNS_STYLES,
      },
    ],
    date: [
      {
        name: 'Date',
        selector: 'updatedAt',
        sortable: true,
        width: '150px',
        style: ADDITIONAL_COLUMNS_STYLES,
      },
    ],
  };

  const riskAnalysisColumns: IDataTableColumn<Batch>[] = [
    BatchTypeEnum.HIGH_VALUES,
    BatchTypeEnum.HIGH_VALUES_EXPORT,
  ].includes(batchView)
    ? [
        {
          name: 'Impact %',
          selector: 'riskAnalysis.impactInPercentage',
          sortable: true,
          width: '100px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.ANALYSIS);
              }}
            >
              {formatValue(row.riskAnalysis?.impactInPercentage || 0, FormatValueTypes.PERCENTAGE)}
            </StatisticLink>
          ),
        },
        {
          name: 'Minimum amount €',
          selector: 'riskAnalysis.impactInEuro',
          sortable: true,
          width: '100px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.ANALYSIS);
              }}
            >
              {formatValue(row.riskAnalysis?.impactInEuro || 0, FormatValueTypes.PERCENTAGE)}
            </StatisticLink>
          ),
        },
        {
          name: 'Value',
          selector: 'statistics.analysisWrongValue',
          sortable: true,
          width: '85px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.ANALYSIS, FilterRisksValues.INVOICED_RATE_WEB_OK);
              }}
            >
              {renderAnalysisWrongParamsFixed(row, RenderAnalysisParams.VALUE)}
            </StatisticLink>
          ),
        },
        {
          name: 'Desc.',
          selector: 'statistics.analysisWrongDescriptionFixed',
          sortable: true,
          width: '85px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.ANALYSIS, FilterRisksValues.WEB_MATCHING_DESCRIPTION);
              }}
            >
              {renderAnalysisWrongParamsFixed(row, RenderAnalysisParams.DESCRIPTION)}
            </StatisticLink>
          ),
        },
        {
          name: 'HS code',
          selector: 'statistics.analysisWrongHSCodeFixed',
          sortable: true,
          width: '100px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.ANALYSIS, FilterRisksValues.HS_DESCRIPTION_OK);
              }}
            >
              {renderAnalysisWrongParamsFixed(row, RenderAnalysisParams.HS_CODE)}
            </StatisticLink>
          ),
        },
      ]
    : [
        {
          name: 'Split Shipment',
          selector: 'statistics.analysisSplitShipment',
          sortable: true,
          width: '100px',
          style: ADDITIONAL_COLUMNS_STYLES,
          cell: (row) => (
            <StatisticLink
              disabled={row.status.value === 'failed'}
              onClick={() => {
                handleStatisticsClick(row, DeclarationStatus.NOT_SENT, FilterRisksValues.SPLIT_SHIPMENT);
              }}
            >
              {row.statistics?.analysisSplitShipment}
            </StatisticLink>
          ),
        },
        ...(settings.walletEnabled
          ? [
              {
                name: 'Wrong IOSS',
                selector: 'statistics.analysisIOSS',
                sortable: true,
                width: '100px',
                style: ADDITIONAL_COLUMNS_STYLES,
                cell: (row) => (
                  <StatisticLink
                    disabled={row.status.value === 'failed'}
                    onClick={() => {
                      handleStatisticsClick(row, DeclarationStatus.NOT_SENT, FilterRisksValues.WRONG_IOSS);
                    }}
                  >
                    {row.statistics?.analysisIOSS}
                  </StatisticLink>
                ),
              },
              {
                name: 'Undervaluation',
                selector: 'statistics.analysisUndervaluation',
                sortable: true,
                width: '120px',
                style: ADDITIONAL_COLUMNS_STYLES,
                cell: (row) => (
                  <StatisticLink
                    disabled={row.status.value === 'failed'}
                    onClick={() => {
                      handleStatisticsClick(row, DeclarationStatus.NOT_SENT, FilterRisksValues.UNDERVALUATION);
                    }}
                  >
                    {row.statistics?.analysisUndervaluation}
                  </StatisticLink>
                ),
              },
            ]
          : []),
      ];

  const columns: IDataTableColumn<Batch>[] = [
    {
      name: 'Status',
      sortable: false,
      width: '80px',
      cell: (row) => <BatchStatusIndicator key={`status-indicator-${row._id}`} batch={row} />,
    },
    {
      name: 'Batch ID',
      selector: 'counter',
      sortable: true,
      width: '100px',
    },
    {
      name: 'Total',
      selector: 'statistics.total',
      sortable: true,
      width: '100px',
    },
    ...(columnsDropdownOptions.riskAnalysis.checked ? riskAnalysisColumns : []),
    ...(columnsDropdownOptions.sending.checked ? sendingColumns : []),
    ...(columnsDropdownOptions.fileName.checked ? informationsColumns.fileName : []),
    ...(columnsDropdownOptions.batchName.checked ? informationsColumns.batchName : []),
    ...(columnsDropdownOptions.customer.checked ? informationsColumns.customer : []),
    ...(columnsDropdownOptions.date.checked ? informationsColumns.date : []),
    ...(columnsDropdownOptions.templateName.checked ? informationsColumns.templateName : []),
    {
      name: '', // hack for flexbox + flex-grow + sticky - it captures the remaining space
    },
    {
      name: 'Actions',
      id: 'actions',
      right: true,
      width: '180px',
      cell: (row, index) => {
        let tableActionsList = [];
        let secondaryActionsList = [TableActions.Edit];
        if (['invalidating-declarations'].includes(row.progress?.status)) {
          return (
            <BatchProgressIndicator
              status={row.progress.status}
              value={row.progress.value}
              processedUrls={row.progress?.processedUrls}
              totalWebUrls={row.progress?.totalWebUrls}
            />
          );
        }

        if (
          [BatchStatusEnum.IN_PROGRESS, BatchStatusEnum.SCRAPING, BatchStatusEnum.SENDING].includes(
            row.status.value as any,
          )
        ) {
          secondaryActionsList = [];
          // if more than 6 hours have passed since the batch is in processing, show the delete button
          if (moment(row.updatedAt, 'DD-MM-YY @ HH:mm').add(3, 'hour') < moment()) {
            tableActionsList.push(TableActions.Delete);
          } else if (row.progress) {
            return <BatchProgressIndicator status={row.progress.status} value={row.progress.value} />;
          }
        } else if (row.status.value === 'failed') {
          tableActionsList.push(TableActions.Delete);
          secondaryActionsList.push(TableActions.Update);
        } else {
          if (row.progress?.status === 'screenshot-in-progress') {
            return (
              <BatchProgressIndicator
                status={row.progress.status}
                value={row.progress.value}
                processedUrls={row.progress?.processedUrls}
                totalWebUrls={row.progress?.totalWebUrls}
              />
            );
          }
          if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)) {
            if (
              row.status.value === BatchStatusEnum.IN_VALIDATION ||
              !row.riskAnalysis ||
              !row.statistics ||
              row.statistics.analysisWrongDescription ||
              row.statistics.analysisWrongHsCode ||
              row.statistics.analysisWrongValue
            ) {
              tableActionsList.push(TableActions.RiskAnalysisIteration);
            }

            secondaryActionsList.push(TableActions.GenerateRiskAnalysisReport);
            secondaryActionsList.push(TableActions.GenerateCustomerReport);

            // ori-1030: Hide action "Send risk analysis report" in batch
            // if (
            //   row.status.value !== BatchStatusEnum.IN_VALIDATION &&
            //   !(row.status.value === BatchStatusEnum.SUCCESS && row.statistics?.sent === row.statistics?.total)
            // ) {
            //   secondaryActionsList.push(TableActions.SendRiskAnalysisReport);
            // }

            if (
              row.statistics.rejected > 0 ||
              (row.statistics.sent === 0 &&
                row.statistics.beGateSent === row.statistics.beGateReleased &&
                (row.status.value !== BatchStatusEnum.SUCCESS || row.statistics?.sent !== row.statistics?.total))
            ) {
              secondaryActionsList.push(TableActions.Update);
            }
            if (row.status.value === BatchStatusEnum.SUCCESS) {
              secondaryActionsList.push(TableActions.Finalize);
            }
            if (declarant.hasInvoiceTemplate && row.statistics.released === row.statistics.total) {
              secondaryActionsList.push(TableActions.GenerateInvoice);
            }

            secondaryActionsList.push(TableActions.ExportScreenshots, TableActions.RiskAnalysisCalculations);
          }

          if (decideIfSendAllButtonIsAvailable(row)) {
            tableActionsList.push(TableActions.ExecuteAction);
          }
          if (deleteAllowed(row)) {
            tableActionsList.push(TableActions.Delete);
          }

          if (
            ![BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView) &&
            !secondaryActionsList.includes(TableActions.Update)
          ) {
            secondaryActionsList.push(TableActions.Update);
          }
          tableActionsList.push(TableActions.Archive);
          if (row.hasFileInCloud) {
            secondaryActionsList.push(TableActions.DownloadFile);
          }

          if (batchView === BatchTypeEnum.LOW_VALUES || row.statistics?.sent > 0 || row.statistics?.beGateSent > 0) {
            secondaryActionsList.push(TableActions.CustomsSync);
          }

          if (row.status.value === BatchStatusEnum.SENDING) {
            tableActionsList = [];
            secondaryActionsList = [];
          }
        }

        secondaryActionsList.push(TableActions.GenerateCustomsReport);

        if (row.status.subStatus === BatchStatusEnum.ANALYSIS) {
          tableActionsList = [];
          secondaryActionsList = [];

          if (row.status.value === BatchStatusEnum.FAILED) {
            tableActionsList.push(TableActions.Delete);
            secondaryActionsList.push(TableActions.Edit, TableActions.Update);
          }
        }

        return (
          <TableActionButtons
            actions={tableActionsList}
            secondaryActions={secondaryActionsList}
            noSpaceBetweenButtons
            onClick={(actionType) => {
              handleTableAction(actionType, row);
            }}
            moveMenuUpwards={pagination.size - index <= 3}
            showDebugMenu={true}
          />
        );
      },
    },
  ];

  const archivedColumns: IDataTableColumn<Batch>[] = [
    {
      name: 'Batch ID',
      selector: 'counter',
      sortable: true,
      width: `100px`,
    },
    {
      name: 'Batch name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: (row) => customers.find((c) => c._id === row.customer)?.name || '',
      width: '260px',
    },
    {
      name: 'File name',
      selector: 'filename',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'updatedAt',
      sortable: true,
    },
    {
      name: currentTab.current === BatchesTabs.ARCHIVED ? '' : 'Error',
      selector: 'status.details',
      sortable: false,
      width: `${currentTab.current === BatchesTabs.ARCHIVED ? 250 : 500}px`,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row, index) => {
        const tableActionsList = [];
        if (deleteAllowed(row)) {
          tableActionsList.push(TableActions.Delete);
        }
        tableActionsList.push(TableActions.Unarchive);
        if (row.hasFileInCloud) {
          tableActionsList.push(TableActions.DownloadFile);
        }
        const secondaryActionsList = [
          TableActions.Edit,
          TableActions.GenerateRiskAnalysisReport,
          TableActions.GenerateInvoice,
          TableActions.ExportScreenshots,
          TableActions.GenerateCustomsReport,
        ];
        if (row.canCleanup && !row.canDelete) {
          secondaryActionsList.push(TableActions.Cleanup);
        }
        return (
          <TableActionButtons
            actions={tableActionsList}
            secondaryActions={secondaryActionsList}
            noSpaceBetweenButtons
            onClick={(actionType) => {
              handleArchivedTableAction(actionType, row);
            }}
            moveMenuUpwards={pagination.size - index < 3}
          />
        );
      },
    },
  ];

  const filterByUserHandler = (userOption: IListFilterChangeEvent) => {
    if (!usersList) {
      return;
    }

    let filterByUsers: string[];
    if (!userOption.id) {
      if (userOption.checked) {
        filterByUsers = usersList.map((usr) => usr._id);
      } else {
        filterByUsers = [];
      }
    } else {
      filterByUsers = usersList.reduce((acc, cur) => {
        if (
          (userOption.id === cur._id && userOption.checked) ||
          (filters.filterByUsers?.includes(cur._id) && userOption.id !== cur._id)
        ) {
          acc.push(cur._id);
        }
        return acc;
      }, []);
    }

    if (!isEqual(filterByUsers, filters.filterByUsers)) {
      dispatch(
        setBatchesFilters({
          filterByUsers,
        }),
      );
    }
  };

  const choiceClassName = () => {
    if (
      columnsDropdownOptions.sending &&
      columnsDropdownOptions.fileName &&
      columnsDropdownOptions.batchName &&
      columnsDropdownOptions.date &&
      columnsDropdownOptions.templateName &&
      !columnsDropdownOptions.riskAnalysis
    ) {
      return 'sending-and-informations-view-table';
    }

    if (
      columnsDropdownOptions.showAll ||
      (columnsDropdownOptions.sending &&
        columnsDropdownOptions.fileName &&
        columnsDropdownOptions.batchName &&
        columnsDropdownOptions.date &&
        columnsDropdownOptions.templateName &&
        columnsDropdownOptions.riskAnalysis)
    ) {
      return 'all-view-table';
    }

    if (
      columnsDropdownOptions.riskAnalysis &&
      columnsDropdownOptions.fileName &&
      columnsDropdownOptions.batchName &&
      columnsDropdownOptions.date &&
      columnsDropdownOptions.templateName &&
      !columnsDropdownOptions.sending
    ) {
      return 'risk-and-informations-view-table';
    }

    if (
      (columnsDropdownOptions.riskAnalysis &&
        !(
          columnsDropdownOptions.sending &&
          columnsDropdownOptions.fileName &&
          columnsDropdownOptions.batchName &&
          columnsDropdownOptions.date &&
          columnsDropdownOptions.templateName
        )) ||
      (columnsDropdownOptions.riskAnalysis &&
        columnsDropdownOptions.sending &&
        !(
          columnsDropdownOptions.fileName &&
          columnsDropdownOptions.batchName &&
          columnsDropdownOptions.date &&
          columnsDropdownOptions.templateName
        ))
    ) {
      return 'risk-view-table';
    }

    if (
      columnsDropdownOptions.fileName &&
      columnsDropdownOptions.batchName &&
      columnsDropdownOptions.date &&
      columnsDropdownOptions.templateName &&
      !(columnsDropdownOptions.riskAnalysis && columnsDropdownOptions.sending)
    ) {
      return 'informations-view-table';
    }

    return '';
  };

  const afterCreateBatchModal = () => {
    return loadBatches();
  };

  const onTabChangeHandler = (tab: BatchesTabs) => {
    currentTab.current = tab;
    dispatch(
      setBatchesFilters({
        status: tab === BatchesTabs.ARCHIVED ? BatchStatusEnum.ARCHIVED : undefined,
      }),
    );
  };

  const handlePagination = (updatedPagination) => {
    if (viewBatchesAsList) {
      dispatch(nextBatches(updatedPagination));
    }
  };

  const handleSorting = (updatedSorting) => dispatch(setBatchesSorting(updatedSorting));

  return (
    <PageContainer isRelative className="batch-list">
      <>
        <PageHeader>
          <PageTitle>Shipment batches</PageTitle>
          <DataTableControls>
            <BatchViewComponent
              value={batchView}
              onChange={(item) => {
                loadBatches(item);
                setBatchView(item);
              }}
            />
            <Button outline onClick={toggleNewBatchModal}>
              New batch
            </Button>
            <SearchBox
              handleSearch={searchBatches}
              value={filters?.searchQuery}
              placeholder="Search by batch name or file name"
            />
          </DataTableControls>
        </PageHeader>
        <Style.NavFilters>
          {viewBatchesAsList && (
            <Style.ListFilters>
              <ListFilterDropdown
                title={'Users visibility'}
                values={usersDropdownOptions.current}
                onChange={filterByUserHandler}
              />
              <ListFilterDropdown
                title={'Columns visibility'}
                values={columnsDropdownOptions}
                onChange={updateColumnsVisibility}
              />
            </Style.ListFilters>
          )}
          <Tabs
            style={{ padding: '0 50px', marginTop: '20px' }}
            onSelectedTabChange={onTabChangeHandler}
            selectedTab={currentTab.current}
          >
            {tabs.current.map((tab) => (
              <Tab key={tab.id} id={tab.id} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Style.NavFilters>
        {viewBatchesAsList && (
          <CustomTable
            className={`${choiceClassName()} data-table-container`}
            columns={currentTab.current === BatchesTabs.IN_PROGRESS ? columns : archivedColumns}
            pagination={pagination}
            sorting={sorting}
            onPaginationChange={handlePagination}
            onSortingChange={handleSorting}
            data={batchesData}
            total={initialTotalBatches}
            isLoading={false}
            disablePagination={isPageLoading}
            disableJumpToLastPage={true}
          />
        )}
        {!viewBatchesAsList && currentTab.current === BatchesTabs.IN_PROGRESS && (
          <BatchesBoard externalFilters={filters} onContextualMenuClick={handleTableAction} batchView={batchView} />
        )}
      </>
      {openRiskAnalsisModal && (
        <RiskAnalysisModal
          batch={selectedUpdateRow.current}
          onHide={async () => {
            await loadBatches();
            dispatch(setDetails(null));
            setOpenRiskAnalsisModal(false);
          }}
        />
      )}
      {showFinalizeBatchModal && (
        <FinalizeBatchModal batch={selectedRow.current} onHide={handleFinalizeBatchHide}></FinalizeBatchModal>
      )}
      {showBulkUpdateBatchModal && (
        <BulkUpdateBatchModal
          afterCreate={loadBatches}
          onHide={toggleBulkUpdateBatchModal}
          show={showBulkUpdateBatchModal}
          updatedBatch={selectedUpdateRow.current}
        />
      )}
      {showUpdateBatchModal && (
        <UpdateBatchModal
          handleFirstButton={toggleLoadANewFileBatchModal}
          handleSecondButton={toggleBulkUpdateBatchModal}
          handleThirdButton={toggleInvalidateBatchModal}
          onHide={toggleUpdateBatchModal}
          isHighValue={
            (selectedUpdateRow.current && selectedUpdateRow.current.templateType === TemplateType.HIGH_VALUE_H1) ||
            false
          }
          show={showUpdateBatchModal}
          batch={selectedUpdateRow.current}
        />
      )}
      {showGenerateBatchInvoiceModal && (
        <NotificationModal
          title="Generate Invoice"
          confirmButtonText="Yes"
          show={showGenerateBatchInvoiceModal}
          onHide={toggleGenerateBatchInvoiceModal}
          onConfirm={() =>
            dispatch(generateInvoice(selectedUpdateRow.current?._id, selectedUpdateRow.current?.filename))
          }
        >
          <div>Do you really want to generate the invoice?</div>
        </NotificationModal>
      )}
      {showLoadANewFileBatchModal && (
        <NewBatchModal
          afterCreate={afterCreateBatchModal}
          isUpdate={true}
          onHide={toggleLoadANewFileBatchModal}
          show={showLoadANewFileBatchModal}
          updatedBatch={selectedUpdateRow.current}
          batchType={batchView}
        />
      )}
      {showInvalidateBatchModal && (
        <InvalidateBatchModal
          afterCreate={afterCreateBatchModal}
          onHide={toggleInvalidateBatchModal}
          show={showInvalidateBatchModal}
          title={INVALIDATE_BATCH}
          updatedBatch={selectedUpdateRow.current}
        />
      )}
      {showNewBatchModal && (
        <NewBatchModal
          show={showNewBatchModal}
          onHide={toggleNewBatchModal}
          afterCreate={afterCreateBatchModal}
          batchType={batchView}
        />
      )}
      {showBatchNotificationModal && (
        <SendBatchModal batch={selectedRow.current} onHide={toggleBatchNotificationModal} />
      )}
      <NotificationModal
        title="Confirm batch delete"
        confirmButtonText="Delete"
        show={showFailedBatchDeleteNotificationModal}
        onHide={toggleDeleteFailedBatchNotificationModal}
        onConfirm={handleBatchDelete}
        isDelete
      >
        <div>Are you sure you want to delete the batch? Action can't be undone!</div>
      </NotificationModal>
      <NotificationModal
        title={isUnarchive ? 'Confirm batch un-archive' : 'Confirm batch archive'}
        confirmButtonText={isUnarchive ? 'Un-archive' : 'Archive'}
        show={showArchiveBatchNotificationModal}
        onHide={toggleArchiveBatchNotificationModal}
        onConfirm={handleBatchArchive}
      >
        <div>
          {isUnarchive
            ? 'Are you sure you want to un-archive the batch?'
            : 'Are you sure you want to archive the batch?'}
        </div>
      </NotificationModal>
      <FailedDetailsModal
        batch={selectedFailedDetailsRow}
        show={showFailedDetailsModal}
        onHide={toggleFailedDetailsModal}
      />
      <Style.ModeSwitcherContainer>
        <Style.ModeSwitcher>
          <Style.ModeButton selected={viewBatchesAsList} onClick={toggleViewMode}>
            <RowsViewIcon />
          </Style.ModeButton>
          <Style.Separator> &nbsp;</Style.Separator>
          <Style.ModeButton selected={!viewBatchesAsList} onClick={toggleViewMode}>
            <ColumnsViewIcon />
          </Style.ModeButton>
        </Style.ModeSwitcher>
      </Style.ModeSwitcherContainer>
    </PageContainer>
  );
};

export default Batches;
