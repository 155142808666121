import { prop } from '@typegoose/typegoose';

export enum RelatedResourceStatus {
  EMPTY = 'EMPTY',
  NOT_SENT = 'NOT_SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
}

export enum RelatedResourceIssuerType {
  DECLARANT = 'DECLARANT',
  SELEER = 'SELLER',
}

export class RelatedResource {
  @prop()
  fileName?: string;

  @prop()
  mediaType?: string;

  @prop()
  digestMultibase?: string;

  @prop({ enum: RelatedResourceStatus })
  status?: RelatedResourceStatus;

  @prop()
  // eslint-disable-next-line camelcase
  status_log?: string[];

  @prop({ enum: RelatedResourceIssuerType })
  issuedBy?: RelatedResourceIssuerType;
}
