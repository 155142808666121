// eslint-disable-next-line no-use-before-define, no-restricted-imports
import { RelatedResourceStatus } from '@e-origin/shared/src/models/customs-domain/related-resource';
// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import * as Style from '../importer-details.style';
import {
  TableActionButtons,
  TableActions,
} from '../../../components/table-action-buttons/table-action-buttons.control';

export interface IIdentificationDocumentItem {
  importer: any;
}

export const ImporterIdentification: React.FC<IIdentificationDocumentItem> = ({ importer }) => {
  const documents = useMemo(
    () =>
      [
        {
          document: 'ID Card',
          filename: '7.FBA15G2WX5...',
          format: 'PDF',
          type: 'ID_CARD',
          relatedResource: { status: RelatedResourceStatus.SENT },
        },
        {
          document: 'Chinese business license',
          filename: '7..AB6567-DE ...',
          format: 'PDF',
          type: 'BUSINESS_LICENSE',
          relatedResource: { status: RelatedResourceStatus.SENT },
        },
        {
          document: 'EORI certificate',
          filename: '6.身份证',
          format: 'JPG',
          type: 'EORI',
          relatedResource: { status: RelatedResourceStatus.SENT },
        },
        {
          document: 'VAT certificate',
          filename: '8.身份证',
          format: 'JPG',
          type: 'VAT',
          relatedResource: { status: RelatedResourceStatus.NOT_SENT },
        },
        {
          document: 'Tax return paperwork',
          filename: '身份证',
          format: 'JPG',
          type: 'TAX',
          relatedResource: { status: RelatedResourceStatus.IN_PROGRESS },
        },
      ].map((doc) => ({
        document: doc.document,
        type: doc.type,
        filename: doc.filename || '',
        format: doc.format || '',
        status: doc.relatedResource?.status || 'EMPTY',
      })),
    [importer],
  );

  const columns: IDataTableColumn[] = [
    {
      name: 'Identification document',
      selector: 'document',
      width: '250px',
    },
    {
      name: 'File name',
      selector: 'filename',
      width: '270px',
    },
    {
      name: 'Format',
      selector: 'format',
      width: '150px',
    },
    {
      name: 'Status',
      selector: 'status',
      width: '290px',
    },
    {
      name: 'Actions',
      right: true,
      cell: () => (
        <TableActionButtons
          actions={[TableActions.Edit]}
          onClick={() => {
            console.log('');
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Style.DocumentsContainer>
        <DataTable noHeader columns={columns} data={documents} pagination />
      </Style.DocumentsContainer>
    </div>
  );
};
