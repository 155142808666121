/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable default-case */
/* eslint-disable complexity */
import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import backIcon from '../../assets/icons/back-icon.svg';
import { Input, Tab, Tabs } from '../../components';

import * as Style from './importer-details.style';
import { BackButton, Header } from './importer-details.style';

import { ImporterGeneralInfo, ImporterIdentification, ImporterPOA, ImporterInfo } from './tabs/index';
import { useFormik } from 'formik';
import { Importer, RecursivePartial } from '@e-origin/shared';
import { FormContainer } from '../../styles/common';

const ImporterDetails: FC = () => {
  const history = useHistory();

  const formik = useFormik<RecursivePartial<Importer>>({
    initialValues: {
      name: '',
      idCard: {
        ownerName: '',
        expirationDate: null as Date | null,
      },
      businessLicense: {
        nameEN: '',
        nameCN: '',
        registrationNumber: '',
      },
      eori: {
        number: '',
        address: {
          street: '',
          city: '',
          zipCode: '',
          country: '',
        },
      },
      vat: {
        number: '',
        address: {
          street: '',
          city: '',
          zipCode: '',
          country: '',
        },
      },
    },
    onSubmit: (values) => {
      console.log('submitted values', values);
    },
  });

  return (
    <Style.Container>
      <FormContainer style={{ overflow: 'hidden' }} onSubmit={formik.handleSubmit}>
        <Header>
          <BackButton
            style={{ paddingBottom: '30px' }}
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={backIcon} alt="" />
            Back
          </BackButton>
          <Input name="name" placeholder="Importer name" width={25} widthUnit="%" />
        </Header>
        <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
          <Tab label="General informations">
            <ImporterGeneralInfo formik={formik} />
          </Tab>
          <Tab label="Identification documents to upload">
            <ImporterIdentification importer={null} />
          </Tab>
          <Tab label="POA">
            <ImporterPOA />
          </Tab>
          <Tab label="Info contact">
            <ImporterInfo />
          </Tab>
        </Tabs>
      </FormContainer>
    </Style.Container>
  );
};

export default ImporterDetails;
