/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
/* eslint-disable default-case */
/* eslint-disable complexity */

import { PageContainer, PageHeader, PageTitle } from '../../styles/common';

/* eslint-disable max-statements */

import { AddImporterButton, ImporterControls } from './importers.style';
import { ImportersTable } from './importers-table/importers-table.component';

import { SearchBox } from '../../components/search-box/search-box.component';

const Importers = () => {
  return (
    <PageContainer isRelative className="importers">
      <PageHeader>
        <PageTitle style={{ fontWeight: 700, fontSize: '30px' }}>Importers</PageTitle>
        <ImporterControls>
          <AddImporterButton>Add new Importer</AddImporterButton>
          <SearchBox
            handleSearch={() => {
              console.log();
            }}
            placeholder="Search "
          />
        </ImporterControls>
      </PageHeader>

      <ImportersTable />
    </PageContainer>
  );
};

export default Importers;
