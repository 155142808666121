/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { cloneDeep, isEqual, pick, remove } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import {
  Declaration,
  DeclarationCustomsType,
  DeclarationDocumentsEnum,
  DeclarationGoods,
  DeclarationStatus,
  IDeclarationsCriteria,
  IManuallySetPrice,
  IRiskAnalysisGoodsRef,
  RecursivePartial,
  RequestFilters,
  SortDirection,
  TemplateType,
} from '@e-origin/shared';

import { removeUndefined, request, STORAGE_KEYS, templateType } from '../utils';
import { fetchBatches } from './batchesSlice';
import { AppThunk, RootState } from './index';

interface IDeclarationListCriteria extends IDeclarationsCriteria {
  shouldFetch?: boolean;
}

interface IDeclarationsState {
  list: Declaration[];
  filters: RequestFilters;
  criteria: IDeclarationListCriteria;
  selectedDeclaration?: Declaration;
  loadingDetails?: boolean;
  selectedBatchName?: string;
  selectedCustomerName?: string;
  loadingList: boolean;
  totalDeclarations: number;
}

interface IManualPriceSetResult {
  updatedDeclaration: Declaration;
  goodItemId: string;
}

export enum MutateDeclarationDocumentOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

const storedCriteria = () => {
  try {
    return { ...JSON.parse(Cookies.get(STORAGE_KEYS.COOKIES.LIST_DECLARATIONS_CRITERIA)), shouldFetch: true };
  } catch (error) {
    return null;
  }
};

export const defaultDeclarationsCriteria: IDeclarationListCriteria = {
  filters: {
    searchQuery: '',
    fetchArchived: false,
    status: DeclarationStatus.ANALYSIS,
  },
  pagination: { page: 1, size: 10, direction: 1, searchToken: '' },
  sorting: { field: 'updatedAt', direction: SortDirection.DESC },
  shouldFetch: true,
};

const initialState: IDeclarationsState = {
  list: [],
  filters: {},
  criteria: storedCriteria() || defaultDeclarationsCriteria,
  selectedDeclaration: undefined,
  selectedBatchName: undefined,
  loadingList: false,
  totalDeclarations: 0,
};

export const declarationsSlice = createSlice({
  name: 'declarations',
  initialState,
  reducers: {
    setCriteria: (state: IDeclarationsState, action: PayloadAction<IDeclarationListCriteria>) => {
      state.criteria = { ...action.payload };
    },
    setList: (state: IDeclarationsState, action: PayloadAction<Declaration[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
      state.loadingList = false;
    },
    setTotalItems: (state: IDeclarationsState, action: PayloadAction<number>) => {
      state.totalDeclarations = action.payload;
    },
    clearDeclarations: (state: IDeclarationsState) => {
      state.list = [];
      state.selectedDeclaration = undefined;
      state.selectedBatchName = undefined;
      state.criteria = storedCriteria() || defaultDeclarationsCriteria;
    },
    clearSelectedDeclaration: (state: IDeclarationsState) => {
      state.selectedDeclaration = undefined;
    },
    setLoadingDetails: (state: IDeclarationsState, action: PayloadAction<boolean>) => {
      state.loadingDetails = action.payload;
    },
    setDetails: (state: IDeclarationsState, action: PayloadAction<Declaration>) => {
      state.selectedDeclaration = action.payload;
    },
    setCustomerName: (state: IDeclarationsState, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerName = action.payload;
    },
    setBatchName: (state: IDeclarationsState, action: PayloadAction<string | undefined>) => {
      state.selectedBatchName = action.payload;
    },
    updateDeclarationDetails: (state: IDeclarationsState, action: PayloadAction<Declaration>) => {
      let updatedList = [...state.list];
      remove(updatedList, (item: any) => item._id === action.payload._id);
      updatedList = [action.payload, ...updatedList];
      state.list = updatedList;
    },
    updateDeclarationAfterSettingThePrice(state: IDeclarationsState, action: PayloadAction<IManualPriceSetResult>) {
      const updatedList = [...state.list];
      let updatedGoodItem: DeclarationGoods | undefined;
      updatedList.forEach((declaration) => {
        if (declaration._id === action.payload.updatedDeclaration._id) {
          declaration.goodShipmentGlobal = { ...action.payload.updatedDeclaration.goodShipmentGlobal };
          declaration.goodsShipmentItems?.forEach((goodItem: DeclarationGoods) => {
            if (goodItem._id === action.payload.goodItemId) {
              updatedGoodItem = action.payload.updatedDeclaration.goodsShipmentItems?.find(
                (item: any) => item._id === action.payload.goodItemId,
              );
              if (updatedGoodItem) {
                goodItem.evalMethodWebValue = updatedGoodItem.evalMethodWebValue;
                goodItem.webScraping = updatedGoodItem.webScraping;
              }
            }
          });
        }
      });
      state.list = updatedList;
      if (state.selectedDeclaration) {
        state.selectedDeclaration.goodShipmentGlobal = { ...action.payload.updatedDeclaration.goodShipmentGlobal };
        state.selectedDeclaration.goodsShipmentItems?.forEach((goodItem: DeclarationGoods) => {
          if (goodItem._id === action.payload.goodItemId) {
            if (!updatedGoodItem) {
              updatedGoodItem = action.payload.updatedDeclaration.goodsShipmentItems?.find(
                (item: any) => item._id === action.payload.goodItemId,
              );
            }
            if (updatedGoodItem) {
              goodItem.evalMethodWebValue = updatedGoodItem.evalMethodWebValue;
              goodItem.webScraping = updatedGoodItem.webScraping;
            }
          }
        });
      }
    },
    setLoadingList: (state: IDeclarationsState, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
  },
});

export const {
  setList,
  setTotalItems,
  setLoadingList,
  setLoadingDetails,
  setDetails,
  updateDeclarationDetails,
  clearDeclarations,
  setBatchName,
  setCustomerName,
  clearSelectedDeclaration,
  updateDeclarationAfterSettingThePrice,
} = declarationsSlice.actions;

export const selectDeclarations = (state: RootState) => state.declarations.list;

export const selectLoadingDetails = (state: RootState) => state.declarations.loadingDetails;

export const selectDetails = (state: RootState) => state.declarations.selectedDeclaration;

export const selectDeclarationsCriteria = (state: RootState) => state.declarations.criteria;

export const selectBatchName = (state: RootState) => state.declarations.selectedBatchName;

export const selectCustomerName = (state: RootState) => state.declarations.selectedCustomerName;

export const selectTotalDeclarationsCount = (state: RootState) => state.declarations.totalDeclarations;

export const selectLoadingDeclarations = (state: RootState) => state.declarations.loadingList;

export const setDeclarationsLoading = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(setLoadingList(true));
};

export const setDeclarationsCriteria =
  (
    criteria: RecursivePartial<IDeclarationListCriteria>,
    options: { replaceCriteria: boolean; shouldFetch: boolean } = { replaceCriteria: false, shouldFetch: true },
  ): AppThunk<void> =>
  (dispatch: any, getState: any) => {
    const currentCriteria: IDeclarationListCriteria = getState().declarations.criteria;
    if (options.replaceCriteria) {
      dispatch(
        declarationsSlice.actions.setCriteria({
          filters: {
            ...defaultDeclarationsCriteria.filters,
            ...removeUndefined(criteria.filters),
          },
          pagination: {
            ...defaultDeclarationsCriteria.pagination,
            ...criteria.pagination,
          },
          sorting: {
            ...defaultDeclarationsCriteria.sorting,
            ...criteria.sorting,
          },
          shouldFetch: options.shouldFetch,
        }),
      );
      return;
    }

    const mergedFilters = removeUndefined({
      ...currentCriteria.filters,
      ...criteria.filters,
    });

    const filtersChanged = !isEqual(currentCriteria.filters, mergedFilters);
    const sortChanged = isEqual(currentCriteria.sorting, criteria.sorting);

    dispatch(
      declarationsSlice.actions.setCriteria({
        filters: mergedFilters,
        sorting: {
          field: criteria.sorting?.field || currentCriteria.sorting.field || 'updatedAt',
          direction: criteria.sorting?.direction || currentCriteria.sorting.direction || SortDirection.ASC,
        },
        pagination: {
          page: !filtersChanged && !sortChanged ? criteria.pagination?.page || currentCriteria.pagination.page || 1 : 1,
          size: criteria.pagination?.size || currentCriteria.pagination.size || 10,
          direction:
            !filtersChanged && !sortChanged
              ? criteria.pagination?.direction || currentCriteria.pagination.direction || 1
              : 1,
          searchToken:
            !filtersChanged && !sortChanged
              ? criteria.pagination?.searchToken || currentCriteria.pagination.searchToken
              : '',
        },
        shouldFetch: options.shouldFetch,
      }),
    );
  };

export const fetchDeclarationsMetadata = (): AppThunk<Promise<void>> => async (dispatch: any, getState: any) => {
  try {
    const { criteria } = getState().declarations;
    dispatch(setTotalItems(0));
    const { metadata } = await request({
      path: `declarations/declaration-list-metadata`,
      method: 'POST',
      authenticate: true,
      dataObject: { criteria },
    });
    dispatch(setTotalItems(metadata.total));
  } catch (error) {
    console.error(error);
    const axiosError = error as AxiosError;

    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export const fetchDeclarations = (): AppThunk<Promise<void>> => async (dispatch: any, getState: any) => {
  try {
    const { criteria } = getState().declarations;

    if (!criteria.shouldFetch) {
      return;
    }

    dispatch(setLoadingList(true));

    if (!!criteria.filters.customerId) {
      const customer = getState().customers.list.find((customer: any) => customer._id === criteria.filters.customerId);

      if (customer) {
        dispatch(setCustomerName(customer.name));
      }
    } else {
      dispatch(setCustomerName(undefined));
    }

    if (!!criteria.filters.batchId) {
      const batch = getState().batches.list.find((item: any) => item._id === criteria.filters.batchId);
      if (batch) {
        dispatch(setBatchName(batch.name));
      }
    } else {
      dispatch(setBatchName(undefined));
    }

    const { data } = await request({
      path: `declarations/declaration-list`,
      method: 'POST',
      authenticate: true,
      dataObject: { criteria },
    });

    dispatch(
      setDeclarationsCriteria(
        {
          pagination: {
            page: criteria.pagination.page,
            size: criteria.pagination.size,
            direction: criteria.pagination.direction,
            searchToken: data[0]?.paginationToken,
          },
        },
        { shouldFetch: false, replaceCriteria: false },
      ),
    );

    dispatch(
      setList(
        data.map((item: any) =>
          item.goodShipmentGlobal?.firstItemData
            ? item
            : {
                ...item,
                goodShipmentGlobal: {
                  ...item.goodShipmentGlobal,
                  firstItemData: { hsCode: 'N/A', invoiceDescription: 'N/A' },
                },
              },
        ),
      ),
    );
    dispatch(setLoadingList(false));
  } catch (error) {
    dispatch(setLoadingList(false));
    console.error(error);
    const axiosError = error as AxiosError;

    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export const fetchDeclarationsByBatchIdAndStatus =
  (batchId: string, declarationsStatus: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingList(true));
      const { data } = await request({
        path: `declarations/rejected/batchId/${batchId}`,
        method: 'POST',
        authenticate: true,
        dataObject: { declarationsStatus },
      });

      dispatch(setList(data));
      dispatch(setLoadingList(false));
    } catch (error) {
      dispatch(setLoadingList(false));
      console.error(error);
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const invalidateAllReleasedDeclarations =
  (batchId: string, reason: string, isHighValue: boolean): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      if (isHighValue) {
        await request({
          path: `declarations/invalidate-released-to-plda`,
          method: 'POST',
          authenticate: true,
          dataObject: { reason, batchId },
        });
      } else {
        await request({
          path: `idms/invalidate-all`,
          method: 'POST',
          authenticate: true,
          dataObject: { reason, batchId },
        });
      }

      dispatch(fetchBatches());
      toast.success('Invalidation request was sent!');
    } catch (error) {
      dispatch(setLoadingList(false));
      console.error(error);
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const fetchDeclarationDetails =
  (declarationId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(setLoadingDetails(true));
    try {
      const { data }: { data: Declaration } = await request({
        path: `declarations/${declarationId}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setLoadingDetails(false));
      dispatch(setDetails(data));
    } catch (error) {
      dispatch(setLoadingDetails(false));
      console.error(error);
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const savePartialDataToDeclaration =
  (
    id: string,
    partialDeclaration: RecursivePartial<Declaration>,
    partialGoodsShipmentItem?: RecursivePartial<DeclarationGoods>,
    storeSavedDeclaration = true,
  ): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `declarations/${id}/save-partial-data-to-declaration`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { partialDeclaration, partialGoodsShipmentItem },
      });

      if (storeSavedDeclaration) {
        dispatch(setDetails(data));
      }

      toast.success('Declaration changes were saved!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const mutateDeclarationDocument =
  (
    doc: {
      operation: MutateDeclarationDocumentOperation;
      type: DeclarationDocumentsEnum;
      id: { [key: string]: string };
      data?: any;
    },
    lrn: string,
    sequence: number,
  ): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `declarations/${doc.operation.toLowerCase()}-document`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { lrn, sequence, document: doc },
      });
      dispatch(fetchDeclarationDetails(data._id));
      toast.success(`The document was ${doc.operation.toLowerCase()}d!`);
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const saveDeclarationDataLRN =
  ({
    lrn,
    sequence,
    partialDeclaration,
    partialGoodsShipmentItem,
    storeSavedDeclaration = true,
  }: {
    lrn: string;
    sequence: number;
    partialDeclaration: RecursivePartial<Declaration>;
    partialGoodsShipmentItem?: RecursivePartial<DeclarationGoods>;
    storeSavedDeclaration?: boolean;
  }): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `declarations/${lrn}/save-declaration-data-lrn`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { lrn, sequence, partialDeclaration, partialGoodsShipmentItem },
      });

      if (storeSavedDeclaration) {
        dispatch(fetchDeclarationDetails(data._id));
      }

      toast.success('Declaration changes were saved!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const checkIntracomVat =
  (lrn: string, eori: string): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { isValid } = await request({
        path: `declarations/${lrn}/check-intacom-vat`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { eori },
      });
      const selectedDeclaration: Declaration = cloneDeep(getState().declarations.selectedDeclaration);
      selectedDeclaration.stakeholders.intracom.identificationNumber = eori;
      selectedDeclaration.stakeholders.intracom.evalVAT = {
        vatOk: isValid,
        result: selectedDeclaration.stakeholders.intracom.evalVAT?.result,
      };

      dispatch(setDetails(selectedDeclaration));

      toast.success('Declaration changes were saved!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const checkImporterEori =
  (lrn: string, eori: string): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { isValid } = await request({
        path: `declarations/${lrn}/check-importer-eori`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { eori },
      });
      const selectedDeclaration: Declaration = cloneDeep(getState().declarations.selectedDeclaration);
      selectedDeclaration.stakeholders.importer.identificationNumber = eori;
      selectedDeclaration.stakeholders.importer.evalEORI = {
        eoriOk: isValid,
        result: selectedDeclaration.stakeholders.importer.evalEORI?.result,
      };

      dispatch(setDetails(selectedDeclaration));

      toast.success('Declaration changes were saved!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const saveDeclarationDetails =
  (
    updatedDeclaration: Declaration,
    propertiesToSend: string[] = [],
    storeSavedDeclaration = true,
  ): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(
      updateDeclarationDetails({
        ...updatedDeclaration,
        goodsShipmentItemsLength: updatedDeclaration.goodsShipmentItems?.length || 0,
      }),
    );
    try {
      const declaration = propertiesToSend.length
        ? pick(updatedDeclaration, ['_id', ...propertiesToSend])
        : updatedDeclaration;

      if (declaration.goodsShipmentItems) {
        // To prevent payload too large errors, don't send data which didn't change on frontend
        declaration.goodsShipmentItems = declaration.goodsShipmentItems.map((item) => {
          const {
            commodityCode,
            dutiesAndTaxes,
            evalMethodStatisticalValue,
            evalMethodWebValue,
            webScraping,
            ...remainingProperties
          } = item;

          if (templateType() === TemplateType.LOW_VALUE_H7) {
            return item;
          }

          return remainingProperties as any;
        });
      }

      const { data } = await request({
        path: `declarations/${updatedDeclaration._id}`,
        method: 'PATCH',
        authenticate: true,
        dataObject: {
          declaration,
        },
      });

      if (storeSavedDeclaration) {
        dispatch(setDetails(data));
      }
      toast.success('Declaration changes were saved!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const sendAllDeclarationsWithSameLRN = async (
  batchId: string,
  movementReferenceNumber?: string,
  lrns: string[] = [],
) => {
  try {
    await request({
      path: `declarations/${batchId}/resend-declarations-with-same-lrn`,
      method: 'POST',
      authenticate: true,
      dataObject: { movementReferenceNumber, lrns },
    });
    toast.success('Started sending all declarations!');
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(error);
    let errorMessage = 'Error sending all declarations!';
    if (axiosError.response?.data.message && axiosError.response?.data.message.includes('Batch is already sending')) {
      errorMessage = 'Batch is already sending!';
    }
    toast.error(errorMessage);
  }
};

export const retrieveDeclarationsRefsByImpact = async (batchId: string) => {
  try {
    const { data }: { data: IRiskAnalysisGoodsRef[] } = await request({
      path: `declarations/${batchId}/retrieve-declarations-refs-by-impact`,
      method: 'GET',
      authenticate: true,
    });
    return data;
  } catch (error) {
    console.error(error);
    const axiosError = error as AxiosError;
    toast.error(axiosError.response?.data.message || 'Something went wrong');
    return [];
  }
};

export const setCustomsControl = async (id: string) => {
  try {
    await request({
      path: `declarations/${id}/set-customs-control`,
      method: 'POST',
      authenticate: true,
    });
    toast.success(`Declarations updated!`);
  } catch (error) {
    toast.error('Error updating the declarations!');
  }
};

export const duplicateAcceptedDeclarationsByLRN = async (lrn: string) => {
  try {
    await request({
      path: `declarations/${lrn}/duplicate-accepted-declarations-by-lrn`,
      method: 'POST',
      authenticate: true,
    });
    toast.success('Duplication done!');
  } catch (error) {
    toast.error("Couldn't duplicate!");
  }
};

export const generateBatchByLRN = async (lrn: string, batchName: string, regulate = false) => {
  try {
    await request({
      path: `declarations/${lrn}/generate-batch-by-lrn`,
      method: 'POST',
      authenticate: true,
      dataObject: { batchName, regulate },
    });

    toast.success(regulate ? 'Regularisation done!' : 'Duplication done!');
  } catch (error) {
    toast.error(regulate ? `Couldn't regulate!` : `Couldn't duplicate!`);
  }
};

export const saveDeclarationMultipleGoodItemsDetails =
  (
    updatedGoodItems: DeclarationGoods[],
    declarationId: string,
    aiModuleMustRunAfterSave?: boolean,
    newPriceSaved?: number,
    storeSavedDeclaration = true,
  ): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      // To prevent payload too large errors, don't send data which didn't change on frontend
      const goodItems = updatedGoodItems.map((goodItem) => {
        const { commodityCode, dutiesAndTaxes, webScraping, ...remainingProperties } = goodItem;
        return remainingProperties as any;
      });

      const { data } = await request({
        path: `declarations/${declarationId}/save-multiple-good-items`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { goodItems, aiModuleMustRunAfterSave, newPriceSaved },
      });

      if (storeSavedDeclaration) {
        dispatch(setDetails(data));
      }
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const sendOneLowValue = async (declarationId: string, movementReferenceNumber?: string) => {
  try {
    await request({
      path: `idms/send-one-declaration`,
      method: 'POST',
      authenticate: true,
      dataObject: {
        movementReferenceNumber,
        declarationId,
      },
    });
    toast.success('Declaration sent successfully!');
  } catch (error) {
    console.error(error);
  }
};

export const sendOneHighValue = async (LRN: string, movementReferenceNumber?: string) => {
  try {
    await request({
      path: `idms/send-one-declaration`,
      method: 'POST',
      authenticate: true,
      dataObject: {
        movementReferenceNumber,
        LRN,
      },
    });
    toast.success('Declaration sent successfully!');
  } catch (error) {
    console.error(error);
  }
};

export const downloadScreenshotFromLink =
  (screenshotLink: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const response = await fetch(screenshotLink, { method: 'GET' });

      const buffer = await response.arrayBuffer();

      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'screenshot.jpeg');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
      toast.error('Error downloading the screenshot!');
    }
  };

export const invalidateOneLowValue =
  (reason: string): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const declaration: Declaration = { ...getState().declarations.selectedDeclaration };
    if (declaration.customsState) {
      try {
        await request({
          path: `idms/invalidate`,
          method: 'POST',
          authenticate: true,
          dataObject: { declarationId: declaration._id, reason },
        });
        toast.success('Invalidation request was sent!');
        dispatch(fetchDeclarationDetails(declaration._id));
      } catch (error) {
        console.error(error);
        toast.error('Error sending the invalidation request!');
      }
    }
  };

export const invalidateOneHighValue =
  (reason: string): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const declaration: Declaration = { ...getState().declarations.selectedDeclaration };
    if (declaration.customsState) {
      try {
        if (
          [DeclarationCustomsType.IDMS, DeclarationCustomsType.DMS].includes(
            declaration.customsState?.sentTo as DeclarationCustomsType,
          )
        ) {
          await request({
            path: `idms/invalidate`,
            method: 'POST',
            authenticate: true,
            dataObject: { LRN: declaration.messageInfo.LRN, reason },
          });
        } else {
          await request({
            path: `declarations/invalidate-one-released-to-plda`,
            method: 'POST',
            authenticate: true,
            dataObject: { LRN: declaration.messageInfo.LRN, reason },
          });
        }
        toast.success('Invalidation request was sent!');
        dispatch(fetchDeclarationDetails(declaration._id));
      } catch (error) {
        console.error(error);
        toast.error('Error sending the invalidation request!');
      }
    }
  };

export const manuallySetPrice =
  (declarationId, goodItemsIds: string[], newPrice: number): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const {
        data: { declaration },
      }: { data: IManuallySetPrice } = await request({
        path: `declarations/${declarationId}/manuallySetPrice`,
        method: 'POST',
        authenticate: true,
        dataObject: { goodItemsIds, price: newPrice },
      });
      dispatch(setDetails(declaration));
      toast.success('Price was set successfully!');
    } catch (error) {
      console.error(error);
      toast.error('Error setting the price!');
    }
  };

export const manuallySetScreenshot =
  (goodItemId: string, file: any): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      if (goodItemId && goodItemId.length && file) {
        const selectedDeclaration = { ...getState().declarations.selectedDeclaration };
        const formData = new FormData();
        formData.append('file', file);
        formData.append('goodItemId', goodItemId);
        const { declaration } = await request({
          path: `declarations/${selectedDeclaration._id}/manuallySetScreenshot`,
          method: 'POST',
          authenticate: true,
          dataObject: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        dispatch(updateDeclarationAfterSettingThePrice({ goodItemId, updatedDeclaration: declaration }));
        toast.success('Screenshot was set successfully!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error setting the screenshot!');
    }
  };

export const deleteScreenshot =
  (goodItemId: string): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      if (goodItemId && goodItemId.length) {
        const selectedDeclaration = { ...getState().declarations.selectedDeclaration };
        const formData = new FormData();
        formData.append('goodItemId', goodItemId);
        const { declaration } = await request({
          path: `declarations/${selectedDeclaration._id}/deleteScreenshot`,
          method: 'POST',
          authenticate: true,
          dataObject: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        dispatch(updateDeclarationAfterSettingThePrice({ goodItemId, updatedDeclaration: declaration }));
        toast.success('Screenshot was deleted successfully!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error deleting the screenshot!');
    }
  };

export const generateDailyReport = async (date: string): Promise<void> => {
  const toasterId = toast.info('Generating daily report..', {
    containerId: 'intrastToaster',
    autoClose: false,
    closeButton: false,
  });

  try {
    const { data, headers } = await request(
      {
        path: `declarations/generate-daily-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
        dataObject: {
          date,
        },
      },
      true,
    );

    const href = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    const reportFilename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'intrastat-daily.xlsx';
    link.setAttribute('download', reportFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.update(toasterId, {
      render: 'Successfully generated the daily report!',
      autoClose: 3000,
      closeButton: true,
      type: 'success',
    });
  } catch (error) {
    console.error(error);
    toast.update(toasterId, {
      render: 'Error generating the daily report!',
      autoClose: 3000,
      closeButton: true,
      type: 'error',
    });
  }
};

export const generateBatchesReport = async (batchNames: string[]): Promise<void> => {
  const toasterId = toast.info('Generating batches report..', {
    containerId: 'generateBatchesReport',
    autoClose: false,
    closeButton: false,
  });

  try {
    const { data, headers } = await request(
      {
        path: `declarations/generate-batches-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
        dataObject: {
          batchNames,
        },
      },
      true,
    );

    const href = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    const reportFilename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'intrastat-daily.xlsx';
    link.setAttribute('download', reportFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.update(toasterId, {
      render: 'Successfully generated the batches report!',
      autoClose: 3000,
      closeButton: true,
      type: 'success',
    });
  } catch (error) {
    console.error(error);
    toast.update(toasterId, {
      render: 'Error generating the batches report!',
      autoClose: 3000,
      closeButton: true,
      type: 'error',
    });
  }
};

export const generateIntrastatReport = async (
  year: number,
  month: number | null,
  quarter: number | null,
): Promise<void> => {
  const toasterId = toast.info('Generating intrastat report..', {
    containerId: 'intrastToaster',
    autoClose: false,
    closeButton: false,
  });

  try {
    const { data, headers } = await request(
      {
        path: `declarations/generate-intrastat-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
        dataObject: {
          quarter,
          month,
          year,
        },
      },
      true,
    );

    const href = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    const reportFilename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'intrastat-report.xlsx';
    link.setAttribute('download', reportFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.update(toasterId, {
      render: 'Successfully generated the intrastat report!',
      autoClose: 3000,
      closeButton: true,
      type: 'success',
    });
  } catch (error) {
    console.error(error);
    toast.update(toasterId, {
      render: 'Error generating the intrastat report!',
      autoClose: 3000,
      closeButton: true,
      type: 'error',
    });
  }
};

export const generateIntervatReport = async (year: number, month: number | null, quarter: number | null) => {
  const toasterId = toast.info('Generating intervat report..', {
    containerId: 'intrastToaster',
    autoClose: false,
    closeButton: false,
  });

  try {
    const { data, headers } = await request(
      {
        path: `declarations/generate-intervat-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
        dataObject: {
          quarter,
          month,
          year,
        },
      },
      true,
    );

    const href = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    const filename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'intervat-report.xml';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.update(toasterId, {
      render: 'Successfully generated the intervat report!',
      autoClose: 3000,
      closeButton: true,
      type: 'success',
    });
  } catch (error) {
    console.error(error);
    toast.update(toasterId, {
      render: 'Error generating the intervat report!',
      autoClose: 3000,
      closeButton: true,
      type: 'error',
    });
  }
};

export const generateIntracomReport = async (
  year: number,
  month: number | null,
  quarter: number | null,
): Promise<void> => {
  const toasterId = toast.info('Generating intracom report..', {
    containerId: 'intrastToaster',
    autoClose: false,
    closeButton: false,
  });

  try {
    const { data, headers } = await request(
      {
        path: `declarations/generate-intracom-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
        dataObject: {
          quarter,
          month,
          year,
        },
      },
      true,
    );

    const href = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    const filename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'intracom-report.xml';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.update(toasterId, {
      render: 'Successfully generated the intracom report!',
      autoClose: 3000,
      closeButton: true,
      type: 'success',
    });
  } catch (error) {
    console.error(error);
    toast.update(toasterId, {
      render: 'Error generating the intracom report!',
      autoClose: 3000,
      closeButton: true,
      type: 'error',
    });
  }
};

export default declarationsSlice.reducer;
