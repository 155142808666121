/* eslint-disable max-lines */
/* eslint-disable max-statements */

import { RecursivePartial, Importer, ImporterStatus } from '@e-origin/shared';
import { CustomTable } from '../../../components';
import { useImportersTableColumns } from './importers-table.columns';

export const importersList: RecursivePartial<Importer[]> = [
  {
    _id: 1,
    status: ImporterStatus.NEW,
    name: 'Importer 1',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
  {
    _id: 2,
    status: ImporterStatus.IN_PROGRESS,
    name: 'Importer 2',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
  {
    _id: 3,
    status: ImporterStatus.EMPTY,
    name: 'Importer 3',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
  {
    _id: 4,

    status: ImporterStatus.NOK,
    name: 'Importer 4',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
  {
    _id: 5,
    status: ImporterStatus.OK,
    name: 'Importer 5',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
  {
    _id: 6,
    status: ImporterStatus.INVALID,
    name: 'Importer 6',
    eori: {
      number: 'DE9270170986',
    },
    vat: { number: 'BE0967777541' },
    updatedAt: '30-05-24 @11:24',
  },
];

export const ImportersTable: React.FC = () => {
  const columns = useImportersTableColumns();

  return (
    <>
      <CustomTable
        columns={columns.current}
        pagination={{ page: 1, size: 10, direction: 1 }}
        sorting={{ direction: 'asc', field: 'messageInfo.batchId' }}
        onSortingChange={() => {
          ('');
        }}
        onPaginationChange={() => {
          ('');
        }}
        data={importersList || []}
        total={0}
        isLoading={false}
        disablePagination={true}
        disableJumpToLastPage={false}
      />
    </>
  );
};
